import * as React from 'react';

const App: React.FunctionComponent = () => {
  const [invoiceAmount, setInvoiceAmount] = React.useState(0);
  const [holdAmount, setHoldAmount] = React.useState(0);

  const round = (a: number, p = 100) => Math.floor(a * (p / 100));

  const needed = 2000;

  const rows = [
    {
      name: 'SL',
      percentage: 35,
    },
    {
      name: 'IB',
      percentage: 35,
    },
    {
      name: 'AU',
      percentage: 25,
    },
    {
      name: 'BD',
      percentage: 5,
    },
  ];

  const calculateRest = () =>
    Math.round(
      (invoiceAmount -
        rows.reduce((p, c) => {
          return p + round(invoiceAmount, c.percentage);
        }, 0)) *
        100,
    ) / 100;

  return (
    <div className="container">
      <div className="py-4 text-center">
        <i className="fas fa-lg fa-coins" />
        <h2>Verdeler</h2>
      </div>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row g-1">
            <div className="col-12">
              <div className="input-group">
                <span className="input-group-text">€</span>
                <input
                  type="number"
                  className="form-control"
                  min={0}
                  value={invoiceAmount}
                  onChange={(e) =>
                    setInvoiceAmount(parseFloat(e.currentTarget.value))
                  }
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Wat</th>
                <th scope="col">Hoeveel</th>
              </tr>
            </thead>
            <tbody>
              {rows.map(({ name, percentage }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={i}>
                  <th scope="row">
                    {name} - <span className="small">{percentage}%</span>
                  </th>
                  <td>€ {round(invoiceAmount, percentage)}</td>
                </tr>
              ))}
              <tr>
                <th scope="row">Rest</th>
                <td>€ {calculateRest().toString().replace('.', ',')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="py-4 text-center">
        <i className="fas fa-lg fa-chart-line" />
        <h2>Hold</h2>
      </div>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row g-1">
            <div className="col-12">
              <div className="input-group">
                <span className="input-group-text">€</span>
                <input
                  type="number"
                  className="form-control"
                  min={0}
                  value={holdAmount}
                  onChange={(e) =>
                    setHoldAmount(parseFloat(e.currentTarget.value))
                  }
                />
              </div>
            </div>
          </div>
        </form>

        <div
          className={`mt-5 text-center text-${
            Math.round((holdAmount / needed) * 100) / 100 < 1
              ? 'danger'
              : 'success'
          }`}
        >
          € {holdAmount} ={' '}
          {(Math.round((holdAmount / needed) * 100) / 100)
            .toString()
            .replace('.', ',')}{' '}
          {Math.round((holdAmount / needed) * 100) / 100 < 2
            ? 'maand'
            : 'maanden'}
        </div>
      </div>
    </div>
  );
};

export default App;
